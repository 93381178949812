import { graphql } from "gatsby";
import React from "react";
import ComposableBlocks from "../components/BlockContent/ComposableBlocks";
import { gridSquares } from "../styles/grid";
import { Icon } from "../components/Icon";
import { Head } from "../components/Head";
import styled from "styled-components";
import { theme } from "../styles/theme";
import { IconType } from "../types";
import { bold12Type, regular12Type } from "../styles/typography";
import { List } from "../components/List";
import { Heading } from "../components/Typography/Heading";
import { Hyperlink } from "../components/Link/Hyperlink";
import { RouteHeading } from "../components/RouteHeading";
import { ContentGroups } from "../constants/contentGroups";

const Container = styled.div`
    display: grid;
    grid-template-areas:
        "header"
        "contacts"
        "description"
        "articles";

    ${(p) => p.theme.media("xl")} {
        grid-template-areas:
            "header contacts"
            "description contacts"
            "articles contacts";
    }
`;

const Capabilities = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: ${gridSquares(2)};
    grid-area: header;
`;

const Description = styled.div`
    grid-area: description;
    margin-bottom: ${gridSquares(2)};
`;

const Contacts = styled.div`
    grid-area: contacts;
    margin-left: ${gridSquares(2)};
`;

const RelatedArticles = styled.div`
    max-width: 800px;
    grid-area: articles;
`;

const CapabilitiesUl = styled.ul`
    display: flex;
    align-items: center;

    > :not(:first-child) {
        margin-left: ${gridSquares(2)};
    }
`;

const CapabilityLi = styled.li`
    ${regular12Type};
`;

const Divider = styled.div`
    grid-area: contacts;
    padding: 36px 24px;

    ${(p) => p.theme.media("xl")} {
        border-left: 1px solid ${(p) => p.theme.color.divider.subtle};
    }
`;

const HeadingLessMargin = styled(Heading)`
    margin-bottom: ${gridSquares(1)};
`;

const HeadingNoMargin = styled(Heading)`
    margin: 0;
`;

const Partner = (props: { data: GatsbyTypes.PartnerPageQuery }) => {
    const {
        canDemo,
        canInstall,
        canOptimize,
        landingPage,
        landingPageSubheading,
        partnerContacts,
        partnerName,
        partnerWebsite,
        scaledAgile,
    } = props.data.sanityPartner;

    /** These two functions pick the partner description out of all of the 'landingPage' information shared by sanity.
     * This was done so description and filler content (blogs, articles, etc...) can be split separate for contact
     * information to be displayed between them. A better solution would be to change sanity for company description
     * to be its own field, however this would require a refactor of all partner pages.
     */
    const pageDescription = landingPage.filter((obj) => {
        return obj._type === "pageBlockContent";
    });

    const fillerContent = landingPage.filter((obj) => {
        return obj._type !== "pageBlockContent";
    });

    const pageSubheading = landingPageSubheading || "An Easy Agile Partner";

    return (
        <>
            <Head
                contentGroup={ContentGroups.partners}
                title={`Easy Agile Partner - ${partnerName}`}
                description={pageSubheading}
            />

            {partnerName && (
                <RouteHeading
                    title={partnerName}
                    subtitle={pageSubheading}
                ></RouteHeading>
            )}
            <Container>
                <Capabilities>
                    <HeadingLessMargin level="h2" levelStyle="h4">
                        Capabilities
                    </HeadingLessMargin>
                    <CapabilitiesUl>
                        {canDemo && <CapabilityLi>Demo</CapabilityLi>}
                        {canInstall && <CapabilityLi>Install</CapabilityLi>}
                        {canOptimize && <CapabilityLi>Agile</CapabilityLi>}
                        {scaledAgile && (
                            <CapabilityLi>Scaled Agile</CapabilityLi>
                        )}
                    </CapabilitiesUl>
                </Capabilities>
                <Description>
                    <ComposableBlocks blocks={pageDescription} />
                </Description>
                <RelatedArticles>
                    <ComposableBlocks blocks={fillerContent} />
                </RelatedArticles>
                <Contacts>
                    <Divider>
                        <HeadingNoMargin level="h2" levelStyle="h4">
                            Contact Details
                        </HeadingNoMargin>
                        <PartnerContactInfo
                            partnerContacts={partnerContacts}
                            partnerWebsite={partnerWebsite}
                        />
                    </Divider>
                </Contacts>
            </Container>
        </>
    );
};
//

export default Partner;

export const pageQuery = graphql`
    query PartnerPage($id: String!) {
        sanityPartner(id: { eq: $id }) {
            canDemo
            canInstall
            canOptimize
            landingPage {
                ... on SanityBlockList {
                    ...SanityBlockListFields
                }
                ... on SanityBlockTestimonial {
                    ...SanityBlockTestimonialFields
                }
                ... on SanityBlockVideo {
                    ...SanityBlockVideoFields
                }
                ... on SanityRelatedArticles {
                    ...SanityRelatedArticlesFields
                }
                ... on SanityPageBlockContent {
                    ...SanityPageBlockContentFields
                }
            }
            landingPageSubheading
            partnerContacts {
                _key
                countries
                emailAddress
                phoneNumber
            }
            partnerName
            partnerWebsite
            scaledAgile
        }
    }
`;

const Layout = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > :not(:first-child) {
        margin-top: ${gridSquares(2)};
    }
`;

const PartnerContact = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    @media (max-width: 400px) {
        flex-direction: column;
    }
`;

const PartnerDetailsItemName = styled.h2`
    width: 200px;
    ${bold12Type}
    margin-right: ${gridSquares(2)};

    @media (max-width: 400px) {
        margin-bottom: ${gridSquares(1)};
    }
`;

const Li = styled.li`
    > :not(:first-child) {
        margin-left: ${gridSquares(0.5)};
    }
`;

const ContactsList = styled(List)`
    width: auto;
    margin-left: 0;
    margin-top: ${gridSquares(1)};
`;

function PartnerContactInfo({
    partnerContacts,
    partnerWebsite,
}: {
    partnerContacts: GatsbyTypes.PartnerPageQuery["sanityPartner"]["partnerContacts"];
    partnerWebsite: GatsbyTypes.PartnerPageQuery["sanityPartner"]["partnerWebsite"];
}) {
    return (
        <Layout>
            {partnerContacts.map(
                ({ _key, countries = [], emailAddress, phoneNumber }) => (
                    <PartnerContact key={_key}>
                        <PartnerDetailsItemName>
                            {countries.join(", ")}
                        </PartnerDetailsItemName>
                        <ContactsList>
                            <Li>
                                <Icon
                                    type={IconType.email}
                                    color={theme.color.divider.main}
                                    size="s"
                                />
                                <a href={`mailto:${emailAddress}`}>
                                    {emailAddress}
                                </a>
                            </Li>
                            {phoneNumber && (
                                <Li>
                                    <Icon
                                        type={IconType.phone}
                                        color={theme.color.divider.main}
                                        size="s"
                                    />
                                    <a href={`tel:${phoneNumber}`}>
                                        {phoneNumber}
                                    </a>
                                </Li>
                            )}
                        </ContactsList>
                    </PartnerContact>
                )
            )}
            {partnerWebsite && (
                <PartnerContact>
                    <PartnerDetailsItemName>Website</PartnerDetailsItemName>
                    <ContactsList>
                        <Li>
                            <Hyperlink href={partnerWebsite}>
                                {partnerWebsite}
                            </Hyperlink>
                        </Li>
                    </ContactsList>
                </PartnerContact>
            )}
        </Layout>
    );
}
